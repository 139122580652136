export const QueryKeyIdentifiers = {
  indDocsByFileId: 'ind-docs-by-file-id',
  affectedSectionNumbers: 'affected-section-numbers',
};

export const FILES = 'files';

export const QueryKeys = {
  GET_RECOMMENDED_FILES_AND_OR_SOURCE_SECTIONS: (sectionNumber: string) => [
    'recommended-files-and-or-source-sections',
    sectionNumber,
  ],
  GET_IND_APPLICATIONS: () => ['ind-applications'],
  GET_IND_APPLICATION: (id: string) => ['ind-application', id],
  GET_IND_APP_TEMPLATE: (id: string) => ['ind-application-template', id],
  GET_ORG_STATS: ['organization-stats'],
  GET_ORG_MEMBERS: ['organization-members'],
  GET_ORGANIZATION: ['organization'],
  GET_ORGANIZATION_ROLES: ['org-roles'],
  GET_ORGANIZATION_ROLE_DETAIL: (orgRoleId: string) => [
    'org-role-detail',
    orgRoleId,
  ],
  ORGANIZATION_ROLE: ['org-role'],
  SWITCH_ORGANIZATIONS: ['switch-organizations'],
  GET_USER_PERMISSIONS: ['user-permissions'],
  GET_DOSSIER_TREE: ['dossier'],
  USER_ROLES: ['user-roles'],
  GET_PROFILE: ['profile'],
  GET_SECTION_INFO: ({
    sectionId,
    indId,
  }: {
    sectionId: string;
    indId: string;
  }) => ['section-info', sectionId, indId],
  GET_SUBSECTIONS: ({
    sectionId,
    indId,
  }: {
    sectionId: string;
    indId: string;
  }) => ['subsections', sectionId, indId],
  GET_SOURCE_FILES: (indId: string, params?: SourceFilesQueryParams) => {
    if (params?.folder_id) {
      return ['source-files', indId, params.folder_id];
    }

    return [FILES, indId];
  },
  GET_SOURCE_FOLDERS: (indId: string, parentId?: string | null) => {
    if (parentId) {
      return ['source-folders', indId, parentId];
    }

    return ['source-folders', indId];
  },
  GET_TEMPLATES: ['templates'],
  GET_ALL_SOURCE_FILES: (props: {
    indId: string;
    include_image_preview_url: boolean;
  }) => ['all-source-files', props.indId, props.include_image_preview_url],
  GET_All_SOURCE_FOLDERS: (indId: string) => ['all-source-folders', indId],
  GET_IND_SOURCE_FILE_COUNT: (id: string) => ['ind-source-file-count', id],
  GET_DOCUMENT_TYPES: () => ['document-types'],
  GET_SECTION_SOURCE_FILES: (props: { sectionId: string; indId: string }) => [
    'section-source-files',
    props.sectionId,
    props.indId,
  ],
  GET_DOCUMENT_SOURCE_FILES: (props: { sectionId: string; indId: string }) => [
    'document-source-files',
    props.sectionId,
    props.indId,
  ],
  GET_FILE_INSPECT_LINK: (fileId: string) => ['file-inspect-link', fileId],
  GET_SECTION_STATUS: ({
    sectionNumber,
    indId,
  }: {
    sectionNumber: string;
    indId: string;
  }) => ['section-status', sectionNumber, indId],
  GET_SECTION_REFERENCES: ({
    indId,
    sectionNumber,
  }: {
    indId: string;
    sectionNumber: string;
  }) => ['section-source-files', indId, sectionNumber],
  GET_DOCUMENT_REFERENCES: ({
    indId,
    sectionNumber,
  }: {
    indId: string;
    sectionNumber: string;
  }) => ['document-references', indId, sectionNumber],
  GET_DOCUMENT_REFERENCES_REFRESH: ({
    indId,
    sectionNumber,
  }: {
    indId: string;
    sectionNumber: string;
  }) => ['document-references-refresh', indId, sectionNumber],
  GET_GENERATION_TEMPLATES: ({ indId }: { indId: string }) => [
    'generation-templates',
    indId,
  ],
  GET_PRE_SIGNED_URL: (fileExtension: string) => [
    'pre-signed-url',
    fileExtension,
  ],
  GET_MISSING_DATA: (indId: string) => ['missing-data', indId],
  AFFECTED_SECTION_NUMBERS: (indId: string, fileId: string) => [
    QueryKeyIdentifiers.affectedSectionNumbers,
    indId,
    fileId,
  ],
  PRE_SIGNED_FILE_FOR_REPLACE: (fileExtension: string, fileId: string) => [
    'pre-signed-file-for-replace',
    fileExtension,
    fileId,
  ],
  SECTIONS_INPUT_COUNT: (indId: string, sectionNumbers: string[]) => [
    'ind-applications',
    indId,
    ...sectionNumbers,
  ],
  GET_RECOMMENDED_DOCS: (indId: string, sectionNumber: string) => [
    'recommended-docs',
    indId,
    sectionNumber,
  ],
};
