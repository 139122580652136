import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { WeaveRoute } from '~/router/types';

export enum PublicRoutes {
  ACCEPT_INVITATION = '/invitations/:invitationId',
  FORGOT_PASSWORD = '/forgot-password',
  FORGOT_PASSWORD_CONFIRM = '/password-reset-confirm',
  ROOT = '/',
  SIGN_IN = '/signin',
  TECHNOLOGY = '/technology',
  TERMS_OF_SERVICE = '/terms-of-service',
}

const InvitationPage = lazy(() => import('~/features/public/Invitation'));
const ForgotPasswordRequestPage = lazy(
  () => import('~/features/public/ForgotPassword/Request'),
);
const ForgotPasswordConfirmPage = lazy(
  () => import('~/features/public/ForgotPassword/Confirm'),
);
const LoginPage = lazy(() => import('~/features/auth/pages/LoginPage'));
const TechnologyPage = lazy(() => import('~/pages/common/Technology'));
const TermPage = lazy(() => import('~/pages/common/Term'));

export const publicRoutes: WeaveRoute[] = [
  {
    path: '*',
    element: <Navigate to={PublicRoutes.ROOT} />,
    headerType: 'none',
  },
  {
    path: PublicRoutes.ROOT,
    element: <LoginPage />,
    headerType: 'none',
  },
  {
    path: PublicRoutes.ACCEPT_INVITATION,
    element: <InvitationPage />,
    headerType: 'none',
  },
  {
    path: PublicRoutes.FORGOT_PASSWORD,
    element: <ForgotPasswordRequestPage />,
    headerType: 'none',
  },
  {
    path: PublicRoutes.FORGOT_PASSWORD_CONFIRM,
    element: <ForgotPasswordConfirmPage />,
    headerType: 'none',
  },
  {
    path: PublicRoutes.SIGN_IN,
    element: <LoginPage />,
    headerType: 'none',
  },
  {
    path: PublicRoutes.TECHNOLOGY,
    element: <TechnologyPage />,
    headerType: 'none',
  },
  {
    path: PublicRoutes.TERMS_OF_SERVICE,
    element: <TermPage />,
    headerType: 'none',
  },
];
