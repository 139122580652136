import { IndProfile } from '~/core/domain/types';

import { UserReportingProvider } from '..';

export class PendoUserReportingProvider implements UserReportingProvider {
  identify: (profile: IndProfile) => void = (profile) => {
    const enablePendo = !!import.meta.env.VITE_ENABLE_PENDO;
    if (enablePendo && pendo) {
      pendo.initialize({
        visitor: {
          // @j-weave: We've explicitly changed this (and the account id below) at product's request
          // to make identification of the users/orgs in our analytics dashboards more accessible.
          id: profile.email,
          email: profile.email,
          firstName: profile.firstName,
          lastName: profile.lastName,
          role: profile.role,
          indId: profile.inds[0]?.id,
          uuid: profile.uuid,
          friendlyName: profile.friendlyName,
        },
        account: {
          id: profile.organization.name,
          accountName: profile.organization.name,
          accountUuid: profile.organization.uuid,
        },
      });
    }
  };

  resetIdentification: () => void = () => {
    // Apparently unsupported by Pendo
  };
}
