/// <reference types="vite-plugin-svgr/client" />

import {
  AlignLeftOutlined,
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleFilled,
  CloseOutlined,
  CloseSquareOutlined,
  CloudSyncOutlined,
  DeleteColumnOutlined,
  DeleteOutlined,
  DeleteRowOutlined,
  DownOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExportOutlined,
  EyeOutlined,
  FilePdfOutlined,
  FileSearchOutlined,
  FilterFilled,
  FilterOutlined,
  FilterTwoTone,
  HolderOutlined,
  ImportOutlined,
  InfoCircleFilled,
  InsertRowBelowOutlined,
  InsertRowRightOutlined,
  LeftCircleOutlined,
  LockOutlined,
  MergeCellsOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  RedoOutlined,
  SearchOutlined,
  SmileOutlined,
  StarOutlined,
  ThunderboltOutlined,
  UndoOutlined,
  WarningFilled,
} from '@ant-design/icons';
import { BorderlessTableOutlined } from '@ant-design/icons';
import { PictureOutlined } from '@ant-design/icons';
import { ForwardRefExoticComponent, RefAttributes } from 'react';

import BorderlessTableOutlinedExtractedSvg from '/src/images/icons/BorderlessTableOutlinedExtracted.svg?react';
import CloudSynced from '/src/images/icons/cloud-synced.svg?react';
import DraggableBlockSvg from '/src/images/icons/draggable-block-menu.svg?react';
import FileSplitArrowSvg from '/src/images/icons/file-split-arrow.svg?react';
import FileWeaveSvg from '/src/images/icons/file-weave.svg?react';
import FormatBold from '/src/images/icons/type-bold.svg?react';
import FormatItalic from '/src/images/icons/type-italic.svg?react';
import FormatStrikeThrough from '/src/images/icons/type-strikethrough.svg?react';
import FormatSubscript from '/src/images/icons/type-subscript.svg?react';
import FormatSuperscript from '/src/images/icons/type-superscript.svg?react';
import FormatUnderline from '/src/images/icons/type-underline.svg?react';

import { createAntIcon, createCustomIcon, IconProps } from './create-ant-icon';

type CreatedAntIcon = (props: IconProps) => JSX.Element;
type CreatedCustomIcon = ForwardRefExoticComponent<
  Omit<IconProps, 'ref'> & RefAttributes<any>
>;
interface WeaveIconContract {
  AlignLeft: CreatedAntIcon;
  ArrowLeft: CreatedAntIcon;
  ArrowRight: CreatedAntIcon;
  ArrowUp: CreatedAntIcon;
  ArrowDown: CreatedAntIcon;
  BorderlessTable: CreatedAntIcon;
  BorderlessTableOutlinedExtracted: CreatedCustomIcon;
  CaretLeft: CreatedAntIcon;
  CaretRight: CreatedAntIcon;
  CaretUp: CreatedAntIcon;
  CaretDown: CreatedAntIcon;
  CheckCircle: CreatedAntIcon;
  CheckCircleFilled: CreatedAntIcon;
  CloseCircleFilled: CreatedAntIcon;
  CloseOutlined: CreatedAntIcon;
  CloseSquare: CreatedAntIcon;
  CloudSynced: CreatedCustomIcon;
  CloudSyncing: CreatedAntIcon;
  Delete: CreatedAntIcon;
  DeleteColumn: CreatedAntIcon;
  DeleteRow: CreatedAntIcon;
  DraggableHandle: CreatedCustomIcon;
  Down: CreatedAntIcon;
  Edit: CreatedAntIcon;
  Ellipsis: CreatedAntIcon;
  Eye: CreatedAntIcon;
  ExportOutlined: CreatedAntIcon;
  FileSearch: CreatedAntIcon;
  FilePDF: CreatedAntIcon;
  FileWeave: CreatedCustomIcon;
  FileSplitArrow: CreatedCustomIcon;
  Filter: CreatedAntIcon;
  FilterFilled: CreatedAntIcon;
  FilterTwoTone: CreatedAntIcon;
  FormatBold: CreatedCustomIcon;
  FormatItalic: CreatedCustomIcon;
  FormatUnderline: CreatedCustomIcon;
  FormatStrikeThrough: CreatedCustomIcon;
  FormatSuperscript: CreatedCustomIcon;
  FormatSubscript: CreatedCustomIcon;
  Holder: CreatedAntIcon;
  Import: CreatedAntIcon;
  InfoCircleFilled: CreatedAntIcon;
  InsertRowBelow: CreatedAntIcon;
  InsertColumnRight: CreatedAntIcon;
  LeftCircle: CreatedAntIcon;
  Lock: CreatedAntIcon;
  MergeCells: CreatedAntIcon;
  Picture: CreatedAntIcon;
  Plus: CreatedAntIcon;
  PlusCross: CreatedAntIcon;
  Redo: CreatedAntIcon;
  Search: CreatedAntIcon;
  Smile: CreatedAntIcon;
  Star: CreatedAntIcon;
  Thunderbolt: CreatedAntIcon;
  Undo: CreatedAntIcon;
  VerticalEllipsis: CreatedAntIcon;
  WarningFilled: CreatedAntIcon;
}

export const WeaveIcon: WeaveIconContract = {
  AlignLeft: createAntIcon(AlignLeftOutlined),
  ArrowLeft: createAntIcon(ArrowLeftOutlined),
  ArrowRight: createAntIcon(ArrowRightOutlined),
  ArrowUp: createAntIcon(ArrowUpOutlined),
  ArrowDown: createAntIcon(ArrowDownOutlined),
  BorderlessTable: createAntIcon(BorderlessTableOutlined),
  BorderlessTableOutlinedExtracted: createCustomIcon({
    imageSource: BorderlessTableOutlinedExtractedSvg,
  }),
  CaretLeft: createAntIcon(CaretLeftOutlined),
  CaretRight: createAntIcon(CaretRightOutlined),
  CaretUp: createAntIcon(CaretUpOutlined),
  CaretDown: createAntIcon(CaretDownOutlined),
  CheckCircle: createAntIcon(CheckCircleOutlined),
  CheckCircleFilled: createAntIcon(CheckCircleFilled),
  CloseCircleFilled: createAntIcon(CloseCircleFilled),
  CloseOutlined: createAntIcon(CloseOutlined),
  CloseSquare: createAntIcon(CloseSquareOutlined),
  CloudSynced: createCustomIcon({ imageSource: CloudSynced }),
  CloudSyncing: createAntIcon(CloudSyncOutlined),
  Delete: createAntIcon(DeleteOutlined),
  DeleteColumn: createAntIcon(DeleteColumnOutlined),
  DeleteRow: createAntIcon(DeleteRowOutlined),
  Down: createAntIcon(DownOutlined),
  DraggableHandle: createCustomIcon({
    imageSource: DraggableBlockSvg,
  }),
  Edit: createAntIcon(EditOutlined),
  Ellipsis: createAntIcon(EllipsisOutlined),
  Eye: createAntIcon(EyeOutlined),
  ExportOutlined: createAntIcon(ExportOutlined),
  FileSearch: createAntIcon(FileSearchOutlined),
  FilePDF: createAntIcon(FilePdfOutlined),
  FileSplitArrow: createCustomIcon({
    imageSource: FileSplitArrowSvg,
  }),
  FileWeave: createCustomIcon({
    imageSource: FileWeaveSvg,
  }),
  Filter: createAntIcon(FilterOutlined),
  FilterFilled: createAntIcon(FilterFilled),
  FilterTwoTone: createAntIcon(FilterTwoTone),
  FormatBold: createCustomIcon({
    imageSource: FormatBold,
  }),
  FormatItalic: createCustomIcon({
    imageSource: FormatItalic,
  }),
  FormatUnderline: createCustomIcon({
    imageSource: FormatUnderline,
  }),
  FormatStrikeThrough: createCustomIcon({
    imageSource: FormatStrikeThrough,
  }),
  FormatSuperscript: createCustomIcon({
    imageSource: FormatSuperscript,
  }),
  FormatSubscript: createCustomIcon({
    imageSource: FormatSubscript,
  }),
  Holder: createAntIcon(HolderOutlined),
  Import: createAntIcon(ImportOutlined),
  InfoCircleFilled: createAntIcon(InfoCircleFilled),
  InsertColumnRight: createAntIcon(InsertRowRightOutlined),
  InsertRowBelow: createAntIcon(InsertRowBelowOutlined),
  LeftCircle: createAntIcon(LeftCircleOutlined),
  Lock: createAntIcon(LockOutlined),
  MergeCells: createAntIcon(MergeCellsOutlined),
  Picture: createAntIcon(PictureOutlined),
  Plus: createAntIcon(PlusCircleOutlined),
  PlusCross: createAntIcon(PlusOutlined),
  Redo: createAntIcon(RedoOutlined),
  Search: createAntIcon(SearchOutlined),
  Smile: createAntIcon(SmileOutlined),
  Star: createAntIcon(StarOutlined),
  Thunderbolt: createAntIcon(ThunderboltOutlined),
  Undo: createAntIcon(UndoOutlined),
  VerticalEllipsis: createAntIcon(MoreOutlined),
  WarningFilled: createAntIcon(WarningFilled),
};
