import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { IndUserProfileSettings } from '~/core/domain/types';
import { QueryKeys } from '~/core/hooks/const';

import * as UserService from '../../../core/services/UserService';

export const useProfileData = () => {
  const queryClient = useQueryClient();

  const { isLoading: loadingUserProfile, data: userProfile } = useQuery(
    {
      queryKey: QueryKeys.GET_PROFILE,
      queryFn: async () => await UserService.getProfile(),
    },
    queryClient,
  );

  const updateUserSettings = useMutation<IndUserProfileSettings, Error, any>({
    mutationFn: async (data) => await UserService.updateProfileSettings(data),
  });

  return {
    loadingUserProfile,
    userProfile,
    updateUserSettings,
  };
};
