import posthog from 'posthog-js';

import { IndProfile } from '~/core/domain/types';

import { UserReportingProvider } from '..';

export class PosthogUserReportingProvider implements UserReportingProvider {
  identify: ({ uuid, email }: IndProfile) => void = ({ uuid, email }) => {
    // @j-weave: We've explicitly changed this (and the account id below) at product's request
    // to make identification of the users/orgs in our analytics dashboards more accessible.
    posthog.identify(email, { email, userUuid: uuid });
  };

  resetIdentification: () => void = () => {
    posthog.reset();
  };
}
